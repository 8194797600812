import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';
import Image from 'gatsby-image';
import IconImage from 'reusecore/src/elements/Image';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import GlideCarousel from 'common/src/components/GlideCarousel';
import GlideSlide from 'common/src/components/GlideCarousel/glideSlide';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea
} from './bannerSection.style';
import PlayStoreCSImage from 'common/src/assets/image/play_cs.png';
import AppleCSImage from 'common/src/assets/image/apple_cs.png';

const BannerSection = () => {
  const glideOptions = {
    type: 'carousel',
    perView: 1,
    gap: 0,
  };

  const data = useStaticQuery(graphql`
    query {
      charityJson {
        bannerSlides {
          id
          thumb_url {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#benefits" />
      <ContentWrapper>
        <TextArea>
          <Heading
            content="The #1 Childcare App"
          />
          <Heading
            as="h4"
            content="A Simple child care management software"
          />
          <Text
            content="Manage programs/classrooms, collect fees, observe students, post updates, and stay in touch with families – all from one easy-to-use child care app. 
          "
          />
          <div style={{paddingBottom: 40, display: 'flex'}}>
            <IconImage style={{height: 50, marginRight: 40}} src={PlayStoreCSImage} alt="Coming soon Android" />
            <IconImage style={{height: 50}} src={AppleCSImage} alt="Coming soon Apple" />
          </div>
          
          <Link className="learn__more-btn" to="">
            <span className="hyphen" />
            <span className="btn_text">Request for Demo</span>
          </Link>
        </TextArea>
        <ImageArea>
          <GlideCarousel
            carouselSelector="charitySlide"
            options={glideOptions}
            nextButton={<span className="next_arrow" />}
            prevButton={<span className="prev_arrow" />}
          >
            <Fragment>
              {data.charityJson.bannerSlides.map(slide => (
                <GlideSlide key={slide.id}>
                  <Image
                    fluid={
                      (slide.thumb_url !== null) | undefined
                        ? slide.thumb_url.childImageSharp.fluid
                        : {}
                    }
                    alt={`Charity slide image ${slide.id}`}
                    className="slide_image"
                  />
                </GlideSlide>
              ))}
            </Fragment>
          </GlideCarousel>
        </ImageArea>
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
